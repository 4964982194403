.sk-facebook-feed-load-more-posts {
  border-radius: 25px !important;
  background: #f19830 !important;
  padding: 12px 22px !important;
}

.sk_branding .tutorial_link,
.facebook-feed-user-root-container,
.sk-fb-page-name span {
  display: none !important;
}

.btn {
  border-radius: 25px !important;
  padding: 12px 22px !important;
}

.btn-primary {
  background: #f19830 !important;
  border-color: #f19830 !important;
}

a {
  text-decoration: none !important;
  color: inherit !important;
}

.cropped-image {
  width: 100%;
  height: 317px;

  background-image: url("../public/images/handibasket.jpeg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  border-radius: 25% 75% / 50%;
  cursor: help;
}
